@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Inter', sans-serif;
}

.active {
    color: rgb(59 130 246);
}